.contactExpert {
    text-align: center;
    padding: 2rem;
    background-color: #f8f8f8;
    margin: 0;
  }
  
  .icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .description {
    margin-bottom: 1rem;
    color: #666;
    text-align: center !important;
  }
  
  .callButton {
    display: inline-flex;
    align-items: center;
    background-color: #e74c3c;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .phoneIcon {
    margin-right: 0.5rem;
  }