body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.navContainer {
  width: 100vw;
  align-items: center;
  background-color: #ee4266;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 10vw 1vw 15vw;
  gap: 0vw;
  position: fixed;
  z-index: 999;
}

.Navbar1 {
  display: flex;
  align-items: center;
}

.Navbar1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3vw;
}

.navlogo {
  width: 5vw;
}

.navlogo {
  width: 100%;
  height: 100%;
}

.menuToggle {
  cursor: pointer;
  font-size: 24px;
}

.navItems {
  display: flex;
  align-items: center;
}

.navItems form {
  display: flex;
  align-items: center;
}

.navItems input[type="text"] {
  padding: 8px;
  border: none;
  border-radius: 4px;
}

.navItems .languages,
.navItems .signIn,
.navItems .cart,
.navItems .app {
  margin-left: 20px;
}

.navBottom ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navBottom ul li {
  position: relative;
}

.navBottom ul li a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
}

.navBottom ul li a:hover {
  background-color: #f1f1f1;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 500px; */
  width: 50vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
  top: 6vh;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 15vw;
  font-size: 1vw !important;
  text-align: left;
}

.dropdownContent a:hover {
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left !important;
  padding: 1vh 1vw;
}

/* Show the dropdown content on hover */
.dropdown:hover .dropdownContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left;
  padding: 5vh 2vw;
}

.logo {
  width: 4vw;
  border-radius: 50%;
}

.menuToggle {
  display: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.navItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1vw;
}

.location,
.signIn,
.cart,
.app {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
  font-size: 1vw;
}

.location svg {
  font-size: 1.8vw;
}

.location h5 {
  font-size: 1.1vw;
  font-weight: 400;
  margin-bottom: 0;
}

.navContainer a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1vw;
}

form {
  display: flex;
  align-items: center;
}

form input {
  border: none;
  border-radius: 1.2vw;
  padding: 1vh 1vw;
  width: 25vw;
}

form svg {
  margin-left: -3vw;
  font-size: 1.8vw;
}

.navBottom {
  width: 100vw;
  padding-top: 10vh;
  z-index: 999 !important;
}

.navBottom ul {
  width: 100vw;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1vw;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 10vw 1vh;
  position: fixed;
  margin: 0;
  list-style-type: none;
  z-index: 999;
  background-color: #fff;

  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.navBottom ul li {
  width: 10vw !important;
  padding: 0;
  align-items: center;
  text-align: center;
  border-radius: 0.5vw;
  background-color: #fff;
}

.navBottom ul li a {
  color: #000;
  text-decoration: none;
  font-size: 1.2vw;
  font-weight: 600;
  opacity: 1;
  padding: 1vh 1vw;
}

@media (max-width: 768px) {
  .navContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 2vh 6vw 2vh 5vw;
    z-index: 999 !important;
    position: fixed;
  }

  .Navbar1 {
    width: 100%;
    justify-content: space-between;
  }

  .menuToggle {
    display: block;
    font-size: 8vw;
  }

  .navItems {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 5vh 5vw;
    color: #000;
    height: 100vh;
    background-color: #ee4266;
  }

  nav .navBottom {
    /* position: fixed; */
    top: 13vh;
  }

  nav .navBottom ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    justify-content: center;
    align-items: center;
    padding: 0.5vh 5vw 0.5vh;
    position: fixed;
    margin: 9vh -2vh -2vh -2vh;
    list-style-type: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: fixed;
  }

  .navBottom ul li {
    width: 30vw !important;
    padding: 1vw 1vw;
    align-items: center;
    text-align: center;
    border-radius: 0.5vw;
    background-color: #fff;
    /* display: none; */
  }

  .navBottom ul li a {
    display: block;
    padding: 1vh 1vw;
    text-decoration: none;
    color: black;
  }

  .navItems.active {
    display: flex;
    gap: 2vh;
  }

  .logo {
    width: 15vw;
  }

  form {
    display: none;
  }

  form input {
    width: 70vw;
    display: none;
  }

  .location {
    display: none;
  }

  .location,
  .signIn,
  .cart,
  .app,
  .navContainer a,
  .location h5 {
    font-size: 5vw;
    margin-bottom: 0;
    font-weight: 500;
  }

  .location svg {
    font-size: 1.5rem;
  }

  .navBottom {
    display: block;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #fff;
  }

  .navBottom.active {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }

  .navBottom.active ul {
    width: 100vw;
    display: none;
    flex-direction: column;
    background-color: #ee4266 !important;
    align-items: flex-start;
    padding: 0vh 0 10vh 0vw !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    height: 100vh;
  }

  .navBottom ul li {
    width: 15vw !important;
    background-color: transparent;
    /* display: none; */
  }

  .navBottom ul li a {
    font-size: 1rem !important;
    color: #000;
  }

  .dropdownContent{
    min-width: none !important;

  }

  .dropdown:hover .dropdownContent {
    /* width: 100vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: left;
    padding: 5vh 2vw;
    flex-basis: 50% !important;
  }

  .dropdown:hover .dropdownContent a{
    width: 40vw !important;
  }
}
