.about-us {
    max-width: 1200px;
    margin: 12vh auto 5vh;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .feature-card {
    flex-basis: calc(33.333% - 1rem);
    background-color: #fff;
    border: 2px solid #ff9999;
    border-radius: 8px;
    padding: 1.5rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }
  
  .feature-card h2 {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .feature-card p {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
    flex-grow: 1;
  }
  
  @media (max-width: 1024px) {
    .feature-card {
      flex-basis: calc(50% - 0.75rem);
    }
  }
  
  @media (max-width: 768px) {
    .feature-card {
      flex-basis: 100%;
    }
    
    h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .about-us {
      padding: 1rem;
      margin: 15vh auto 5vh;

    }
    
    h1 {
      font-size: 1.8rem;
    }
    
    .feature-card {
      padding: 1rem;
    }
    
    .feature-card h2 {
      font-size: 1.1rem;
      font-weight: 700;
    }
    
    .feature-card p {
      font-size: 4vw;
    }
  }