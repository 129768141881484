.eventCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.card2{
   width: 15vw;
   height: 40vh;
}

.card2 img{
    width: 100%;
    height: 100%;
    border-radius: 1vw;

}

.slick-next, .slick-prev{
    background-color: black !important;
}

.card2 h4{
    text-align: center;
    font-size: 1.3vw;
    margin-top: 2vh;
}

@media (max-width: 768px){
    .card2{
        width: 60vw;
        height: 40vh;
     }

     .card2 h4{
        font-size: 5vw;
        margin-bottom: 3vh;
     }
     
}