.footer {
    background-color: #f8f8f8;
    padding: 5vw 10vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 5vw;
  }
  
  .section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .section ul li {
    margin-bottom: 0.5rem;
  }
  
  .section ul li a {
    color: #333;
    text-decoration: none;
  }
  
  .appStoreButtons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .appStoreButton img {
    height: 40px;
  }
  
  .socialIcons {
    display: flex;
    gap: 1rem;
  }
  
  .socialIcon {
    width: 30px;
    height: 30px;
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-decoration: none;
  }
  
  .countrySelect {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .copyright {
    grid-column: 1 / -1;
    text-align: center;
    margin-top: 2rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .footer {
      grid-template-columns: 1fr;
    }
  }