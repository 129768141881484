.card1{
  width: 50vw;
  /* opacity: 0; */
  padding-top: 9vh;
}

.card1 img{
    width: 100%;
    height: 100%;
}

@media (max-width: 768px){
    .card1{
        width: 100vw;
        /* opacity: 0.3; */
        padding-top: 3vh;
      } 
}