.container{
    padding-top: 0vh;
}

.container .content{
    position: relative;
    top: 5vh;
    text-align: center;
    width: 100vw;
    z-index: 9;
    margin-bottom: 5vh;
}

.container h4{
    margin-top: -1.5vw;
}

.container h3{
    text-align: left;
    margin: 15vh 5vw 1vh;
}

@media (max-width: 768px){
    .container{
        padding-top: 10vh;
    }

    .container .content{
        position: relative;
        top: 5vh;
        text-align: center;
        width: 100vw;
        margin-bottom: 0vh;
    }

}