.venue-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    max-width: 300px;
}

.venue-card-image {
    width: 100%;
    height: auto;
}

.venue-card-content {
    padding: 16px;
}

.venue-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location {
    font-size: 14px;
    color: #888;
}

.venue-card-body {
    margin-top: 8px;
}

.promotion {
    color: green;
    font-weight: bold;
}

.price {
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
}

.capacity {
    font-size: 14px;
    color: #888;
}
