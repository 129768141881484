@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.Container{
    width: 100vw;
    height: 100vh;
    background: radial-gradient(
        circle at center,
    #ee4266, /* Primary Pink */
    #ff577f, /* Bright Pink */
    #ff6b81, /* Coral Pink */
    #f55a8a, /* Vibrant Pink */
    #e94b74, /* Deep Pink */
    #ee4266, /* Primary Pink */
    #d7385e, /* Dark Pink */
    #ff577f  /* Bright Pink */
  );
    padding-top: 15vh;
    padding-bottom: 10vh;
    padding: 25vh 20vw 5vh;
    font-family: "Raleway", sans-serif;
}

.Container h2{
  font-size: 2.5vw;
  text-align: center;
  color: #fff;
  font-weight: 700;
}

.Container h4{
    font-size: 2.5vw;
    text-align: center;
    color: #fff;
}

.Container h1{
    font-size: 5vw;
    text-align: center;
    color: #fff;
    font-weight: 1000;
    text-shadow: 1px 1px 0 #d7385e, 2px 2px 0 #c4284f, 3px 3px 0 #a5173b;

}

@media (max-width: 768px){
    .Container h2{
        font-size: 7vw;
        
      }
      
      .Container h4{
          font-size: 6vw;
          margin-top: 2vh;
      }
      
      .Container h1{
          font-size: 10vw;  
          margin-top: 5vh;
      }
}



